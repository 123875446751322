<template>
  <div
    class="bg-white shadow-md rounded-lg p-2 sm:p-4 my-2 cursor-pointer"
    @click="goToDetails()"
  >
    <div class="flex items-center">
      <div
        class="flex justify-center items-center w-8 h-8 sm:h-12 sm:w-12 rounded-full bg-opacity-10"
      >
        <img :src="getImageByType()" class="h-8 w-8" />
      </div>
      <div class="w-2"></div>
      <div class="flex-1 ml-4">
        <div class="text-xs sm:text-sm font-medium">
          {{ moneyMovement.note ?? '-' }}
        </div>
        <div class="w-1"></div>
        <div class="text-xs text-gray-500 truncate w-20 xs:w-24 sm:w-auto">
          {{ t('available_balance')
          }}{{ moneyFormatter(moneyMovement.balance) }}
          {{ moneyMovement.currency?.code ?? '' }}
        </div>
        <div class="text-xs text-gray-400">
          {{ formatDate(moneyMovement.created_at) }}
        </div>
      </div>

      <div class="ml-4 text-right flex">
        <p class="text-sm font-bold text-center">
          {{ moneyFormatter(moneyMovement.amount) }}
          {{ moneyMovement.currency?.code ?? '' }}
        </p>
        <div class="w-2"></div>
        <v-icon :icon="icon.name" size="22" :color="icon.color" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment'
import {
  MoneyMovementStatus,
  MoneyMovementTypes
} from '~~/composables/useMenuModel'
const props = defineProps<{ moneyMovement: MoneyMovement }>()
const router = useRouter()
const localePath = useLocalePath()
function goToDetails() {
  if (props.moneyMovement.type === MoneyMovementTypes.bill) {
    router.push(
      localePath({
        path: `/my-account/orders/${props.moneyMovement.related_id}`
      })
    )
  }
}
const { t } = useI18n()
function formatDate(date?: string) {
  return moment(date).format(' YYYY/MM/DD').toString()
}

function getImageByType(): string {
  switch (props.moneyMovement.type) {
    case MoneyMovementTypes.bill:
      return '/icons/bill.webp'
    case MoneyMovementTypes.deposit:
      return '/icons/deposit.webp'
    case MoneyMovementTypes.withdrawal:
      return '/icons/withdrawal.webp'
    default:
      return '/icons/amana.webp'
  }
}

function getIconByStatus(): { name: string; color: string } {
  switch (props.moneyMovement.status) {
    case MoneyMovementStatus.debit:
      return {
        name: 'mdi mdi-arrow-up',
        color: 'red'
      }
    case MoneyMovementStatus.credit:
      return {
        name: 'mdi mdi-arrow-down',
        color: 'green'
      }
    default:
      return {
        name: 'mdi mdi-arrow-up',
        color: 'grey'
      }
  }
}
const icon = getIconByStatus()
function moneyFormatter(input: any): string {
  if (input !== null && input !== undefined) {
    let value: number

    if (typeof input === 'string') {
      value = parseFloat(input)
      if (isNaN(value)) {
        value = 0
      }
    } else if (typeof input === 'number') {
      value = input
    } else {
      throw new TypeError("value isn't a parsable number")
    }
    let resultInt = Math.round(value * 10000000)

    resultInt = Math.floor(resultInt / 100000)

    const finalResult = resultInt / 100
    return finalResult.toFixed(2)
  } else {
    return '-'
  }
}
</script>

<style>
.v-timeline--vertical.v-timeline.v-timeline--side-end
  .v-timeline-item
  .v-timeline-item__body {
  grid-column: 3;
  padding-inline-start: 5px !important;
  padding-inline-end: initial;
  justify-self: flex-start;
}
</style>
