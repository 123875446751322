<template>
  <div v-if="balances?.data && balances?.data?.length > 0" class="py-2 mb-4">
    <div class="flex justify-center items-center">
      <span class="text-lg font-bold">{{ t('balance') }}: </span>
      <span
        v-for="(item, index) in balances?.data"
        :key="index"
        class="px-2 py-1 border border-gray-300 rounded-lg mx-1 text-sm"
      >
        {{
          useStringPrice(
            parseFloat(item.local_credit ?? ''),
            item.currency?.code
          )
        }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Balance } from '~/composables/useMenuModel'
const { data: balances } = await useBasicFetch<ApiResponse<Balance[]>>(
  getAllRoutes().moneyMovement.getBalancesByServer
)
const { t } = useI18n()
</script>
